import React, { useRef, useState } from "react"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
import ExpertVideoBox from "../Products/ExpertVideoBox"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
}

const ScientificExpertSlider = ({ data }) => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [videoOpen, setvideoOpen] = useState(null)
  const handleVideo = i => {
    if (data) {
      setmodalOpen(!modalOpen)
      setvideoOpen(data[i].video_url)
    }
  }
  return typeof window !== "undefined" ? (
    <Carousel>
      <div style={{ width: "100%" }}>
        <ExpertVideoBox
          open={modalOpen}
          video={videoOpen}
          closeHandle={() => setmodalOpen(false)}
        />
        <Flickity
          options={flickityOptions}
          className={"carousel"}
          static
          reloadOnUpdate
        >
          {data.map((item, index) => {
            return (
              item.name &&
              item.text && (
                <ExpertBox key={index.toString()}>
                  <ExpertImage>
                    <link preload="true" rel="image" />
                    <Image
                      rel="preload"
                      className="lazyload"
                      data-src={item.image}
                    />
                  </ExpertImage>
                  <ExpertInfo>
                    <BoxTitle dangerouslySetInnerHTML={{ __html: item.name }} />
                    <BoxDesignation
                      dangerouslySetInnerHTML={{ __html: item.designation }}
                    />
                    <BoxText dangerouslySetInnerHTML={{ __html: item.text }} />

                    {item.video_text && (
                      <VideoBox onClick={() => handleVideo(index)}>
                        <PlayIcon
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/19d469eb-6651-40e3-ae5c-38ded39d720b.svg"
                        />
                        {item.video_text}
                      </VideoBox>
                    )}
                  </ExpertInfo>
                </ExpertBox>
              )
            )
          })}
        </Flickity>
      </div>
    </Carousel>
  ) : null
}

export const ExpertBox = styled.div`
  padding: 10px 0px;
  text-align: center;
  margin: 0px 10px;
  position: relative;
  min-height: 200px;
  width: 330px;

  :not(.is-selected) {
    @media (max-width: 1100px) {
      opacity: 0.2;
    }
  }

  @media (max-width: 480px) {
    width: 280px;
    min-height: 215px;
  }
`

export const ExpertImage = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 140px;
  height: 150px;

  @media (max-width: 480px) {
    width: 100px;
    height: auto;
  }
`

export const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
  border-radius: 5px;
  height: 150px;

  @media (max-width: 480px) {
    height: auto;
  }
`

export const ExpertInfo = styled.div`
  position: absolute;
  left: 0px;
  top: 22px;
  right: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 6px;
  opacity: 0.95;
  padding: 15px;
  text-align: left;
  min-height: 127px;

  @media (max-width: 480px) {
    top: 15px;
    right: 40px;
    padding: 10px;
  }
`

export const BoxTitle = styled.h5`
  color: #262626;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: 500;
  margin: 0px;
`

export const BoxDesignation = styled.h5`
  color: #262626;
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham HTF Book";
  font-weight: 500;
  font-style: normal;
  margin-bottom: 15px;
`

export const BoxText = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham HTF Book";
  font-weight: 500;
  font-style: normal;
`

export const VideoBox = styled.a`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
`

export const PlayIcon = styled.img`
  width: 25px;
  object-fit: contain;
  margin: 0px;
  margin-right: 10px;
`

export const Carousel = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;

  @media (max-width: 1100px) {
    display: flex;
  }

  .carousel {
    padding-bottom: 30px;
    @media (max-width: 767px) {
      margin: 0px -20px;
    }
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 30px;
    height: 30px;
    border: 2px solid #111;
    top: 100%;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button.next {
    right: 40%;

    @media (max-width: 767px) {
      right: 20px;
    }
  }
  .flickity-prev-next-button.previous {
    left: 40%;

    @media (max-width: 767px) {
      left: 20px;
    }
  }
  .flickity-page-dots {
    bottom: -6px;
    max-width: 80px;
    margin: 0px auto;
    left: 0;
    right: 0;
  }
`

export const PrevNext = styled.div`
  margin: 0 10px;
  width: 20px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

export const Indicators = styled.img`
  margin: 0;
`

export default ScientificExpertSlider
